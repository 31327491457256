.article-wrapper {
    width: 100%;
    height: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.article-navigation-wrapper {
    overflow-y: scroll;
    overflow-x: clip;
    position: fixed;
    width: 25%;
    height: calc(100vh - 5.375rem - 48px);
    text-align: center;
}

.article-navigation {
    display: inline-block;
    margin: 0 auto;
    text-align: left;
    padding-left: 1.625rem;
    padding-right: 1.625rem;
    box-sizing: border-box;
}

.article-navigation-link {
    display: block;
    padding-bottom: 0.625rem;
    box-sizing: border-box;
}

.article-navigation-link a {
    text-transform: uppercase;
    font-family: "wildrp_website_bold", serif;
    font-size: 1.1em;
    line-height: 0.9;
    font-weight: 400;
    letter-spacing: 0.05em;
    color: #747474;
    text-decoration: none;
    cursor: pointer;
}

.article-navigation-link a:hover {
    color: #ffffff;
}

.article-navigation-link.depth-2 {
    padding-left: 0.625rem;
    font-size: 0.95em;
}

.article-navigation-link.depth-3 {
    padding-left: 1.25rem;
    font-size: 0.9em;
}

.article-navigation-link.depth-4 {
    padding-left: 1.875rem;
    font-size: 0.85em;
}

.article-navigation-link.depth-5 {
    padding-left: 2.5rem;
    font-size: 0.8em;
}

.article-navigation-link.depth-6 {
    padding-left: 3.125rem;
    font-size: 0.75em;
}

.article-content {
    width: 50%;
    margin: auto;
    padding-left: 20px;
    color: #ffffff;
}

.article-content.mobile-article {
    width: 85%;
}

.article-content p, ul, ol, table {
    color: #ffffff;
    opacity: 0.8;
    font-size: 1.2rem;
    font-family: "App", Arial, sans-serif;
    line-height: 1.6;
    font-weight: 300;
    padding-bottom: 5px;
    padding-top: 5px;
}

.article-content blockquote {
    font-style: italic;
}

.article-content table, th, td {
    border: 1px solid white;
    border-collapse: collapse;
    padding: 5px;
}

.article-content h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    font-family: "wildrp_website_light", serif;
    line-height: 0.9;
    font-weight: 400;
    letter-spacing: 0.02rem;
}

.article-content h1 {
    font-size: 3rem;
}

.article-content h2 {
    font-size: 2.8rem;
}

.article-content h3 {
    font-size: 2.5rem;
}

.article-content h3 {
    font-size: 2.2rem;
}

.article-content h4 {
    font-size: 1.9rem;
}

.article-content h5 {
    font-size: 1.6rem;
}

.article-content h6 {
    font-size: 1.3rem;
}

.article-content a {
    color: #e4b85d;
    text-decoration: none;
}

.article-content a:hover {
    color: #ffce66;
}

a.scroll-active {
    color: #e4b85d;
}