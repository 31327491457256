.navbar-wrapper {
    position: absolute;
    width: 100%;
    height: 4.125rem;
    background-color: transparent;
    margin-bottom: 1.25rem;

    font-family: "wildrp_website_bold", serif;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.02rem;
    font-weight: 400;
    color: rgb(116, 116, 116);

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    z-index: 9001;
}

.navbar-content {
    height: 100%;
    width: 100%;
    padding: 0.6rem 1.3rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 0.063rem solid rgba(255, 255, 255, 0.1);
}

.navbar-left {
    order: 0;
    flex-basis: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.link-wrapper {
    display: inline;
    padding-left: 0.313rem;
    padding-right: 0.313rem;
}

.link-wrapper a {
    color: inherit;
    text-decoration: inherit;
}

.link-wrapper a:hover {
    color:white;
}

.link-wrapper.active {
    color:white;
}

.link-wrapper button {
    color: inherit;
    text-decoration: inherit;
    background: none;
    border: none;
    font-family: inherit;
    text-transform: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    padding: 0;
}

.link-wrapper button:hover {
    color:white;
    cursor: pointer;
}

.link-wrapper-block {
    display: block;
    padding: 0.125rem 0.313rem;
}

.link-wrapper-block a {
    color: inherit;
    text-decoration: inherit;
}

.link-wrapper-block a:hover {
    color:white;
}

.link-accent {
    background: rgba(226,181,90,1);
    background: linear-gradient(170deg, rgba(187,122,44,1) 0%, rgba(226,181,90,1) 48%, rgba(183,117,43,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.link-accent a:hover {
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.nav-divider {
    border-left: 0.063rem solid rgba(255, 255, 255, 0.2);
    height: 1.875rem;
    display: inline-block;
    margin-left: 0.625rem;
    padding-right: 0.625rem;
    box-sizing: border-box;
}

img.logo {
    display: block;
    order: 1;
    width: 3.125rem;
    height: 3.125rem;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.navbar-right {
    order: 2;
    flex-basis: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.third-party-logo {
    padding-left: 0.313rem;
    padding-right: 0.313rem;
}

.third-party-logo img {
    display: block;
    width: 1.563rem;
    height: 1.563rem;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    filter: brightness(0.5);
}

.third-party-logo img:hover {
    filter: brightness(1);
}

.dropdown-wrapper {
    position: relative;
    top:-4.125rem;
    opacity: 0;
    left:0;
    width: 100%;
    max-height: 100vh;
    z-index: -1;
    transform: translateY(-100%);
    transition: transform 500ms ease-in-out, opacity 300ms linear;
    box-sizing: border-box;
}

.dropdown-background {
    padding: 5.4rem 1.3rem 1.3rem 1.2rem;
    background-color: rgba(5, 11, 11, 0.8);

    height: 100%;
}

.dropdown-background.opaque {
    background-color: rgba(5, 11, 11, 0.95);
}

.dropdown-wrapper.active {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
}

.dropdown-content {
    opacity: 0;
    transition: opacity 300ms linear;
    transition-delay: 500ms;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.dropdown-content.active {
    opacity: 1;
}

.dropdown-column {
    display: inline-block;
    padding-right: 2.5rem;
}