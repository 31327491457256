.custom-select-wrapper {
    color: #808080;
    border: 1px solid #808080 !important;
    border-radius: 0 !important;
    resize: none;
    font-size: 24px !important;
    font-weight: 300;
    min-height: 70px;
    font-family: "App", Arial, sans-serif;
    margin-bottom: 30px;
}

.custom-select-wrapper:focus-within {
    box-shadow: none !important;
}

.custom-select-wrapper .react-dropdown-select-input {
    font-weight: 300;
    font-size: 24px !important;
    font-family: "App", Arial, sans-serif !important;
}

.custom-select-wrapper .react-dropdown-select-content {
    min-height: 70px;
    align-items: center;
    padding-left: 20px !important;
    padding-right: 20px !important;
    overflow: hidden;
}

.custom-select-wrapper .react-dropdown-select-dropdown {
    border-radius: 0 !important;
    border: 1px solid #808080 !important;
    color: #808080 !important;
    background: black !important;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent; /*just hides the scrollbar for firefox */
}

.custom-select-wrapper .react-dropdown-select-dropdown::-webkit-scrollbar {
    display: none;
}

.custom-select-wrapper .react-dropdown-select-dropdown::-moz-scrollbar {
    display: none;
}

.custom-select-wrapper .react-dropdown-select-item {
    border-bottom: 1px solid #808080 !important;
    color: #808080;
}

.custom-select-wrapper .react-dropdown-select-item:hover {
    color: white !important;
    background: none !important;
}

.custom-select-wrapper .react-dropdown-select-item.react-dropdown-select-item-selected,
.custom-select-wrapper .react-dropdown-select-item.react-dropdown-select-item-active {
    border-bottom: 1px solid #333;
    color: white;
    font-weight: bold;
    background: none !important;
}
.custom-select-wrapper .react-dropdown-select-item.react-dropdown-select-item-disabled {
    background: #808080;
    color: #808080;
}