.background-wrapper {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: -10;
}

.video-background {
    position: absolute;

    --varadjust: calc(var(--widthmod) / 1920);
    width: calc(1920 * var(--varadjust));
    height: calc(1080 * var(--varadjust));

    opacity: 1;
    transition: opacity 300ms;
    z-index: -8;
}

.image-background {
    position: absolute;

    --varadjust: calc(var(--widthmod) / 1920);
    width: calc(1920 * var(--varadjust));
    height: calc(1080 * var(--varadjust));
    z-index: -9;

    opacity: 1;
    transition: opacity 300ms;
}

.fade {
    opacity: 0;
}