.warning {
    display: flex;
    padding: 20px;
    border: 1px solid #e4b85d;
    background-color: rgba(129, 97, 35, 0.1);
    color: rgba(255, 255, 255, 0.9);
    font-family: "App", Arial, sans-serif;
    overflow: auto;
    line-height: 1.5;
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 30px;
    user-select: none;
}

.warning a {
    color: #e4b85d;
    text-decoration: none;
}

.warning-stamp {
    display: inline-block;
}

.warning-stamp img {
    display: block;
    height: 50px;
    width: 50px;
    -webkit-user-drag: none;
}

.warning-content {
    display: inline-block;
    padding-left: 20px;
}