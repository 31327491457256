.not-found-wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "wildrp_website_light", serif;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--heightmod) - 10.75rem);
    user-select: none;
}

.four-o-four {
    font-family: "wildrp_website_bold", serif;
    text-transform: uppercase;
    font-size: 15rem;
    letter-spacing: 0.02rem;
    font-weight: 400;
    background: rgba(226,181,90,1);
    background: linear-gradient(120deg, rgba(187,122,44,1) 0%, rgba(226,181,90,1) 48%, rgba(183,117,43,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.blurb {
    font-size: 2rem;
    color:white;
    margin: 10px;
}