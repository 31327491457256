.success-wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "wildrp_website_light", serif;
    top: 0;
    left: 0;
    width: 75%;
    height: calc(var(--heightmod) - 10.75rem);
    user-select: none;
    margin: auto;
}

.success-wrapper .center-text {
    font-family: "wildrp_website_bold", serif;
    text-transform: uppercase;
    font-size: 5rem;
    letter-spacing: 0.02rem;
    font-weight: 400;
    background: rgba(226,181,90,1);
    background: linear-gradient(120deg, rgba(187,122,44,1) 0%, rgba(226,181,90,1) 48%, rgba(183,117,43,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.success-wrapper .blurb {
    font-size: 2rem;
    color: white;
    margin: 10px;
}

.success-wrapper p {
    color: #ffffff;
    opacity: 0.8;
    font-size: 1.2rem;
    font-family: "App", Arial, sans-serif;
    line-height: 1.6;
    font-weight: 300;
    padding-bottom: 5px;
    padding-top: 5px;
}

.success-wrapper a {
    color: #e4b85d;
    text-decoration: none;
}

.success-wrapper a:hover {
    color: #ffce66;
}