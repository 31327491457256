.login-strategy-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    user-select: none;
}

.login-strategy-wrapper.mobile {
    flex-direction: column;
}

.login-strategy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    filter: brightness(0.5);
    border: 1px solid white;
    border-radius: 5px;
    min-width: 300px;
}

.login-strategy-button:hover {
    filter: brightness(1);
    cursor: pointer;
}

.login-strategy-logo {
    padding: 0.5rem 0.313rem;
}

.login-strategy-logo img {
    display: block;
    width: 1.563rem;
    height: 1.563rem;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.login-strategy-text {
    text-transform: uppercase;
    font-family: "wildrp_website_light", serif;
    font-size: 1.5em;
    line-height: 0.9;
    font-weight: 400;
    letter-spacing: 0.05em;
    color: white;
    text-decoration: none;
    user-select: none;
    margin-top: 3px;
    padding-right: 0.313rem;
    padding-left: 0.313rem;
}

.login-strategy-account {
    display: flex;
    margin-left: 20px;
}

.login-strategy-account.mobile {
    margin-top: 20px;
    margin-left: 0;
}

.profile-picture {
    display: inline-block;
    margin-right: 10px;
}

.profile-picture img {
    display: block;
    height: 40px;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.profile-text {
    text-transform: uppercase;
    font-family: "wildrp_website_light", serif;
    font-size: 1.5em;
    line-height: 0.9;
    font-weight: 400;
    letter-spacing: 0.05em;
    color: white;
    text-decoration: none;
    user-select: none;
    margin-top: 10px;
}

.login-strategy-required {
    text-transform: uppercase;
    font-family: "wildrp_website_light", serif;
    font-size: 1.5em;
    line-height: 0.9;
    font-weight: 400;
    letter-spacing: 0.05em;
    text-decoration: none;
    user-select: none;
    color: #c22323;
}

