.home-wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "wildrp_website_light", serif;
    top: 0;
    left: 0;
    width: 65%;
    height: 100%;
    user-select: none;
    margin: auto;
    color: white;
}

.home-wrapper.mobile {
    width: 95% !important;
}

.home-wrapper .landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(var(--heightmod) - 10.75rem);
    margin-bottom: 5.75rem;
}

.home-wrapper .landing.mobile {
    justify-content: flex-start;
    height: max-content;
    margin-bottom: 2.75rem;
}


.home-wrapper img {
    pointer-events: none;
    width: 100%;
}

.home-wrapper .new-day {
    width: 60%;
}

.home-wrapper .welcome {
    width: 45%;
}

.home-wrapper .arrow {
    margin-top: 10vh;
    width: 5%;
}

.home-wrapper .launcher {
    width: 95%;
}

.home-wrapper p {
    text-align: center;
    font-family: "App", Arial, sans-serif;
    font-weight: 300;
    line-height: 1.4;
    font-size: 1.9em;
    padding-bottom: 0;
    text-transform: none;
    opacity: 0.8;
}

.home-wrapper p.blurb {
    font-size: 1.3em !important;
    letter-spacing: 0.03em;
    line-height: 1.4 !important;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 2em;
    width: 60%;
}

.home-wrapper h1 {
    font-family: "wildrp_website_light";
    font-weight: 300;
    font-style: normal;
    text-align: center;
    line-height: 1.1em;
    font-size: 3em;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    background: rgba(226,181,90,1);
    background: linear-gradient(120deg, rgba(187,122,44,1) 0%, rgba(226,181,90,1) 48%, rgba(183,117,43,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-wrapper a {
    color: #e4b85d;
    opacity: 1;
    font-size: 1.3em !important;
    letter-spacing: 0.03em;
    line-height: 1.4 !important;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
}

.home-wrapper a:hover {
    color: #ffffff;
}

.home-wrapper .app-plug {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15rem;
}

.home-wrapper .app-plug.mobile {
    height: 10rem;
}

.home-wrapper button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    outline: 0;
    background-color: transparent;
    padding-left: 15px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    padding-top: 3px;
    font-family: "wildrp_website_bold", serif;
    font-size: 1.9em;
    height: 55px;
    vertical-align: middle;
    color: #e4b85d;
    border: 2px solid #e4b85d;
    overflow: hidden;
    text-transform: uppercase;
    font-weight: 200;
    box-sizing: border-box;
}

.home-wrapper button:disabled {
    filter: brightness(0.5);
}

.home-wrapper button:hover {
    filter: grayscale(100%) contrast(1000%);
}

.home-wrapper button > .wheel img {
    animation-fill-mode: both;
    animation:spin-out 500ms ease-in-out;
}


.home-wrapper button:hover > .wheel img {
    animation-fill-mode: both;
    animation:spin-in 500ms ease-in-out;
}

.home-wrapper button .wheel {
    height: 55px;
    width: 55px;
    margin-top: -2px;
}

.home-wrapper .socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.home-wrapper .socials.mobile {
    flex-direction: column;
    width: 90%;
}

.home-wrapper .socials .discord {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-wrapper .socials .twitter {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}