.container-wrapper {
    top: 0;
    bottom: 0;
    width: var(--widthmod);
    min-height: var(--heightmod);
    max-width: 100%;
}

.container-wrapper::-webkit-scrollbar {
    display: none;
}

.switch-fade-enter {
    opacity: 0;
}

.switch-fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.switch-fade-exit {
    opacity: 1;
}

.switch-fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}