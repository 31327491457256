.form-wrapper {
    width: 50%;
    margin: auto;
    user-select: none;
}

.form-wrapper.mobile-form {
    width: 85%;
}

.form-title {
    text-transform: uppercase;
    font-family: "wildrp_website_bold", serif;
    font-size: 4rem;
    line-height: 0.9;
    font-weight: 400;
    letter-spacing: 0.05em;
    color: white;
    text-decoration: none;
    user-select: none;
    margin-bottom: 20px;
    margin-top: 48px;
}


.form-wrapper h1 {
    color: #ffffff;
    font-size: 3rem;
    text-transform: uppercase;
    font-family: "wildrp_website_light", serif;
    line-height: 1.1;
    font-weight: 400;
    letter-spacing: 0.02rem;
    margin-bottom: 40px;
    margin-top: 40px;
}

.form-wrapper p, .just-text {
    color: #ffffff;
    opacity: 0.9;
    font-size: 1.2rem;
    font-family: "App", Arial, sans-serif;
    line-height: 1.5;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 20px;
}

.form-wrapper .error-message {
    color: #c22323;
    margin-top: -10px;

    -webkit-animation: fadein 500ms; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 500ms; /* Firefox < 16 */
    -ms-animation: fadein 500ms; /* Internet Explorer */
    -o-animation: fadein 500ms; /* Opera < 12.1 */
    animation: fadein 500ms;
}

.form-wrapper .checkbox-field {
    margin-bottom: 20px;
}

.form-wrapper a {
    color: #e4b85d;
    text-decoration: none;
}

.form-wrapper textarea {
    width: 100%;
    box-sizing: border-box;
    color: #ffffff;
    border: 1px solid #808080 !important;
    background: none;
    resize: none;
    font-size: 24px;
    font-weight: 300;
    font-family: "App", Arial, sans-serif;
    margin-bottom: 30px;
    outline: none;
    padding-top: 20px;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.form-wrapper input[type="text" i], input[type="email" i], input[type="date" i] {
    width: 100%;
    box-sizing: border-box;
    color: #ffffff;
    border: 1px solid #808080 !important;
    background: none;
    resize: none;
    font-size: 24px;
    font-weight: 300;
    font-family: "App", Arial, sans-serif;
    margin-bottom: 30px;
    outline: none;
    padding: 20px !important;
}

.form-wrapper input[type="date" i]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.form-wrapper button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    outline: 0;
    background-color: transparent;
    padding-left: 15px;
    -moz-border-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-top: 3px;
    font-family: "wildrp_website_bold", serif;
    font-size: 1.9em;
    height: 55px;
    vertical-align: middle;
    color: #e4b85d;
    border: 2px solid #e4b85d;
    overflow: hidden;
    text-transform: uppercase;
    font-weight: 200;
    box-sizing: border-box;
}

.form-wrapper button:disabled {
    filter: brightness(0.5);
}

.form-wrapper button:hover {
    filter: grayscale(100%) contrast(1000%);
}

.form-wrapper button > .wheel img {
    animation-fill-mode: both;
    animation:spin-out 500ms ease-in-out;
}


.form-wrapper button:hover > .wheel img {
    animation-fill-mode: both;
    animation:spin-in 500ms ease-in-out;
}

.form-wrapper button .wheel {
    height: 55px;
    width: 55px;
    margin-top: -5px;
}

.form-wrapper .word-count {
    text-align: right;
    margin-top: -25px;
    color: #808080;
}